<template>
  <v-app>
    
    <div
      class="bg-body_ d-flex flex-column justify-center align-center"
    >
    <v-container class="mt-6">
      <div class="mx-auto mb-5 cursor-pointer topDes custom-container">
        <div >
          <img
            style="max-width: 300px;max-height: 80px"
            class="text-center"
            :src="logo.value"
            @click="index()"
          />
        </div>
        <h3 class="hidden-xs-only col-F63605">{{ $t("你身边的省钱助手") }}</h3>
      </div>
    </v-container>
    <div class="sign-up-box">
      <div class="sign-up-card-left">

      </div>
      <div class="sign-up-card">
        <div class="sign-up-card-container">
          <div class="text-left mb-10 col-F63605">
            <h3 class="mb-3">{{ $t("账号登录") }}</h3>
          </div>
          <p class="text-14 mb-2">{{ $t("手机号码或邮箱") }}</p>
          <v-text-field
            placeholder="example@mail.com"
            :rules="Rules"
            outlined
            dense
            class="mb-4"
            v-model="params.username"
          ></v-text-field>
          <p class="text-14 mb-2">{{ $t("登录密码") }}</p>
          <v-text-field
            type="password"
            placeholder="*******"
            :rules="Rules"
            outlined
            dense
            v-model="params.password"
            class=""
            @keyup.enter="loginfunc"
          ></v-text-field>
           <router-link to="/forget-pwd" class="ms-2 col-F63605 text--darken-4 font-600">{{ $t("忘记密码？") }}</router-link>
          <p class="mb-8">
           
          </p>
          
          <v-btn
            color="primary"
            class="text-capitalize font-600 mb-2 py-5"
            block
            @click="loginfunc"
          >
            {{ $t("登录") }}
          </v-btn>
        </div>
        <div class="py-4  lighten-2 d-flex justify-space-around">
          <div class="text-center">
            <span class="grey--text text--darken-1"
              >{{ $t("还没有账号") }}？
              <router-link
                to="/sign-up"
                class="ms-2 col-F63605 text--darken-4 font-600"
                >{{ $t("注册") }}</router-link
              >
            </span>
          </div>
          
        </div>
      </div>
    </div>
    </div>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      checkbox: false,
      params: {
        username: "",
        password: "",
      },
      logo:{},
      Rules: [(v) => !!v || "必填项"],
      caigoudialog: false, //采购商弹窗
    };
  },
  mounted() {
    this.logo=this.$store.state.config.logo[0]
  },
  methods: {
    loginfunc() {
      this.$api.signIn.login(this.params).then((res) => {
        if (res.status=='success') {
          this.$store.commit("setUSERINFO", res.data.user_info);
          this.$store.commit("setTOKEN", res.data.token);
          this.$router.push('/')
          this.pubilce()
        } else {
          this.$Toast({ content: res.msg, type: "error" });
        }
      });
    },
    pubilce(){
      this.$api.user.getUserMsgnum().then((res)=>{
        if(res.status=='success'){
          this.$store.commit("setmsgNum", res.data);
          setTimeout(()=>{
            location.reload() 
          },200)
        }
      })
    },
    index(){
      this.$router.push({
        path: "/",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.col-F63605{
  color: #F63605 !important;
}
.bg-body_{
  width: 100%;
  background:url("../assets/images/login/loginbasebg.png");
  height:100%;			//大小设置为100%
  position:fixed;
  background-size:100% 100%;
}

.sign-up-box{
  width: 1100px;
  height: 650px;
  overflow: hidden;
  // margin: 2rem auto;
  background-color: #fff;
  background-size:100% 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background:url("../assets/images/login/loginbg.png");
  @media (max-width: 500px) {
    width: 100%;
    background-image:none
  }
}
.sign-up-card-left{
  width: 526px;
  height: 486px;
  background-size:100% 100%;
  background: url('../assets/images/login/login-left.svg');
  @media (max-width: 500px) {
    width: 0;
  }
}
.sign-up-card {
  width: 400px;
  background-color: #fff;
  // border-radius: 8px;
  // box-shadow: rgb(3 0 71 / 9%) 0px 8px 45px;
  @media (max-width: 500px) {
    width: 100%;
  }
  .sign-up-card-container {
    padding: 3rem 3.75rem 0px;
    @media (max-width: 500px) {
      padding: 3rem 1rem 0px;
    }
  }
}
</style>